import React, { useState, useRef, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { Link } from "react-router-dom";
import { Toast } from "primereact/toast";
import moment from 'moment-timezone';
import LoadingSpinner from "../loader/LoadingSpinner";
import Authentication from "../../services/AuthenticationService";
import loglevel from "../../logger/Logger"
import AuthenticationService from "../../services/AuthenticationService";
// import LogoTelcoLabs from "../../images/login-page-logo.svg"
// import LogoVmwBcm from "../../images/vmw-bcm-logo-white.svg"
import LogoVmwBcmSdeLabs from "../../images/nextline.svg"
import { useGlobalState } from '../../config/state';
import LabServices from "../../services/LabServices";
import ForgotPassword from "./ForgotPassword";
import { Message } from 'primereact/message';
import { Divider } from 'primereact/divider';
import PortalService from "../../services/PortalService";
import { MAINTENANCE_MODE } from "../../config/configuration";
import { MAINTENANCE_MESSAGE } from "../../config/configuration";
import Footer from "../footer/Footer";
import { Captcha } from 'primereact/captcha';

const Login = () => {
  loglevel.debug("Entering into Login Component");
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  let year = new Date().getFullYear(); // getting current year

  const handleChange = (e) => {
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "email" && e.target.value === "") { // if email field is not provided
      // setEmailErrorMsg("Email is required");
      setEmailErrorMsg("");
      setEmailError(true);
    }
    else if (e.target.name === "email" && !regexEmail.test(e.target.value)) { // if provided email is not valid
      setEmailErrorMsg("Enter a valid email address");
      setEmailError(true);
    }
    else { // for valid email
      setEmailErrorMsg("");
      setEmailError(false);
    }
    if (e.target.name === "password" && e.target.value === "") { // if password is invalid
      // setPasswordErrorMsg("Password is required");
      setPasswordErrorMsg("");
      setPasswordError(true);
    } else { //if password is provided
      setPasswordErrorMsg("");
      setPasswordError(false);
    }
  };

  const [isCaptchaVerified, setCaptchaVerified] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [passwordErrorMsg, setPasswordErrorMsg] = useState("");
  const [wrongPassword, setWrongPassword] = useState(false);
  const [wrongPasswordMsg, setWrongPasswordMsg] = useState("");
  const navigate = useNavigate();
  const logoutToast = useRef(null);

  const [isAdmin, setIsAdmin] = useGlobalState('isAdmin');
  const [adminReportsAccess, setAdminReportsAccess] = useGlobalState('adminReportsAccess')
  const [labCategories, setLabCategories] = useGlobalState('labCategories');

  const ssoAuthenticate = (event) => {
    loglevel.debug("Entering into Login Component: ssoAuthenticate method");
    event.preventDefault();
    // api call for sso login
    AuthenticationService.ssoLogin().then((res) => {
      if (res.status === 200) {
        localStorage.setItem('ssoRequestId', res.data.requestId) // storing sso requestid in local storage
        localStorage.setItem('isSSOLogin', true); // storing whether loged in through sso in local storage
        window.location.assign(res.data.context);
      }

    }).catch((error) => { //if error occur in calling api
      loglevel.error("ssoLogin", error);
    });
  }

  const ssoAuthenticateBcom = (event) => {
    loglevel.debug("Entering into Login Component: ssoAuthenticate method Broadcom");
    event.preventDefault();
    // api call for sso login
    AuthenticationService.ssoLoginBcom().then((res) => {
      if (res.status === 200) {
        localStorage.setItem('ssoRequestId', res.data.requestId) // storing sso requestid in local storage
        localStorage.setItem('isSSOLogin', true); // storing whether loged in through sso in local storage
        window.location.assign(res.data.context);
      }

    }).catch((error) => { //if error occur in calling api
      loglevel.error("ssoLogin", error);
    });
  }

  // for user authentication
  const authenticate = async (event) => {
    loglevel.debug("Entering into Login Component: authenticate method");
    event.preventDefault();
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (state.email === "") { // if email not provided
      // setEmailErrorMsg("Email is required");
      setEmailErrorMsg("");
      setEmailError(true);
    }
    else if (!regexEmail.test(state.email)) { // if provided email is invalid
      setEmailErrorMsg("Enter a valid email address");
      setEmailError(true);
    }
    else { // if email is valid
      setEmailErrorMsg("");
      setEmailError(false);
    }
    if (state.password === "") { // if password is not provided
      // setPasswordErrorMsg("Password is required");
      setPasswordErrorMsg("");
      setPasswordError(true);
    }
    else { //if password is provided
      setPasswordErrorMsg("");
      setPasswordError(false);
    }
    if (state.email !== "" && state.password !== "") { // if email and password are correct and valid
      setLoading(true);
      //request body for login
      const requestBody = {
        email: state.email,
        password: state.password,
      };

      // api call for login
      await Authentication.login(requestBody).then(async (res) => {
        if (res.status === 200) {
          setLoading(false);
          localStorage.setItem("accessToken", res.data.accessTokenDtl.accessToken);
          localStorage.setItem("expiresIn", res.data.accessTokenDtl.expiresIn);
          let param = `?users_id=${res.data.users_id}`;
          loglevel.debug("Login data Response", res)
          localStorage.setItem("isAuthenticated", "true");
          localStorage.setItem("users_id", res.data.users_id);
          localStorage.setItem("fname", res.data.first_name);
          localStorage.setItem("lname", res.data.last_name);
          localStorage.setItem("email", res.data.email);
          //refreshing the lab list
          await LabServices.getRbacLabList(param).then((res) => {
            setIsAdmin(res.data.isAdmin);
            setAdminReportsAccess(res.data.adminReportsAccess);
            setLabCategories(res.data.categories);
          }).catch((error) => {
            loglevel.error("getRbacLabList", error);
          });
          // navigating to category 1
          navigate("/home/labs/1");

          Authentication.getPortalUserCount().then((res) => {
            loglevel.debug("User Portal Count", res);
          }).catch((error) => {
            loglevel.error("User Portal Count", error)
          })
        } else {
          setWrongPasswordMsg("");
          setWrongPassword(false);
        }
      })
        .catch((error) => { //if error is there for login
          setLoading(false);
          if (error.response.data.statusCode === 401 || error.response.data.statusCode === 400) {
            setWrongPasswordMsg(error.response.data.msg);
            loglevel.error("Login", error)
            setWrongPassword(true);
          }
        });
    }
  }

  useEffect(() => {
    //if authentication is true
    if (localStorage.getItem("isAuthenticated") === "true") {
      // navigate to lab listing page
      navigate("home/labs/1")
    }
    let currentDate = new Date();
    let hours = currentDate.getHours();
    let minutes = currentDate.getMinutes();
    let firstname = localStorage.getItem("fname");
    let lastname = localStorage.getItem("lname");
    if (localStorage.getItem("isAuthenticated") === "false") {
      logoutToast.current.show({
        severity: "success",
        summary: "Logout successful",
        detail: `User ${firstname} ${lastname} logged out successfully at ${(hours < 10) ? "0" + hours : hours} : ${(minutes < 10) ? "0" + minutes : minutes} ${moment(new Date()).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z')} `,
        life: 6000,
      });
      localStorage.clear();
    }
  }, [navigate]);

  const verifyCaptcha =  async(value) => {
    console.log("Capcha value:" + JSON.stringify(value))
    await AuthenticationService.verifyCapcha(value.response).then((res) => {
        console.log("Res from google: " + res)
        logoutToast.current.show({ severity: 'info', summary: 'Success', detail: 'Captcha Verified Successfully', life:5000 });
        setCaptchaVerified(true);
    }).catch((error) => {
        console.log("Captcha verify", error)
        if (error.stack.includes("Network Error")) {
          logoutToast.current.show({ severity: 'info', summary: 'Error', detail: 'Network Error' });
        } else {
          logoutToast.current.show({ severity: 'info', summary: 'Error', detail: 'BOT Detected' });
        }
      })
  }

  const expireCaptcha =  async(value) => {
    setCaptchaVerified(false);
    logoutToast.current.show({ severity: 'info', summary: 'Re-Validate Captcha', detail: 'Captcha has been RESET due to Timeout', life:5000 });
  }

  return (
    <div>
      {(MAINTENANCE_MODE.toString() === "true" || MAINTENANCE_MODE.toString() === "True") && <div className="maintainance-container"><div className="floatAboveEverything maintainance-warn"><i className="pi pi-exclamation-triangle" style={{ 'fontSize': '1.5em', marginRight: "10px" }}></i> {MAINTENANCE_MESSAGE}</div></div>}
      <div className={(MAINTENANCE_MODE.toString() === "true" || MAINTENANCE_MODE.toString() === "True") ? "maintainance-mode" : "login"}>
        {loading ? <div className="loader"><LoadingSpinner /></div> : <div>
          <Toast ref={logoutToast} position="top-center" className="logout-toast" />
          <div className="p-grid loginForm">
            <div className="p-col-5  login-left-bar">
              {/* <div className="main-logo p-d-flex p-ai-center">
              <img className="text-logo" src={textLogo} alt="textLogo" />
            </div> */}
              {/* <div className="logo p-d-flex p-jc-center"> */}
              {/* <div className="logo p-d-flex p-jc-center logo-replace-txt-div"> */}
              {/* <img className="logo-image" src={Logo} alt="Logo" /> */}
              <div>
                <div className="logo p-d-flex p-jc-center ">
                  <img src={LogoVmwBcmSdeLabs} alt="Logo" width={"70%"} style={{ marginLeft: "30px" }}/>
                  {/*  <div className="nextline"></div>*/}
                  {/*  resizable SVG using CSS*/}
                  {/*<div className="nextline" style={{ width: "100%", height: "100px"}}></div>*/}
                  {/*<div className="nextline" style={{ width: "100%", maxHeight:"900px", minHeight:"100px"}}></div>*/}

                  {/*  Prev */}
                  {/*<img src={LogoVmwBcm} alt="Logo" width={"150"}/>*/}
                  {/*<img src={LogoTelcoLabs} style={{ marginBottom:"20px", marginLeft: "10px", height:"22px" }}  alt="Logo" />*/}
                </div>
                {/* </div> */}
                <form className="p-fluid p-mx-auto" onSubmit={authenticate} id="form-submit">
                  {wrongPassword && (
                    <Message severity="warn" text={wrongPasswordMsg} className="common-inline-error" />
                  )}
                  <div className="login-title">
                    <p className="login-text">Login</p>
                  </div>
                  <div className="p-field username-field">
                    <InputText
                      id="myusername"
                      type="text"
                      name="email"
                      placeholder="Email"
                      autoComplete="off"
                      value={state.email}
                      onChange={handleChange}
                      className={
                        emailError || wrongPassword
                          ? "input-error"
                          : "user-input-text"
                      }
                    />
                    {emailError && (
                      <small className="text-input-error p-d-block" id="error-message">{emailErrorMsg}</small>
                    )}
                  </div>
                  <div className="password-field">
                    <Password
                      id="mypassword"
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={state.password}
                      onChange={handleChange}
                      toggleMask
                      className={
                        passwordError || wrongPassword
                          ? "input-error"
                          : "password-input-text"
                      }
                    />
                    {passwordError && (
                      <small className="text-input-error p-d-block">{passwordErrorMsg}</small>
                    )}
                  </div>
                  <div className="forgot-password-field p-d-flex p-jc-end">
                    <ForgotPassword />
                  </div>
                  <div>
                    <div className="card">
                      <Captcha siteKey={process.env.REACT_APP_SITE_KEY}
                               onResponse={verifyCaptcha} onExpire={expireCaptcha} />
                      </div>
                    </div>
                  <div className="p-field submit-button-field">
                    <Button
                        id="myloginbutton"
                      label="LOGIN"
                      className={isCaptchaVerified ? "submitBtn" : "submitBtnDisabled"}
                      type="submit"
                      disabled={!isCaptchaVerified}
                    />
                  </div>
                  {/*<p className="or-divider p-d-flex p-jc-center">OR</p>*/}
                  {/*<div className="sso-login">*/}
                  {/*  <Button*/}
                  {/*      id="myssologinbutton"*/}
                  {/*    label="LOGIN using VMWare SSO"*/}
                  {/*    className="submitBtn"*/}
                  {/*    onClick={ssoAuthenticate}*/}
                  {/*  />*/}
                  {/*</div>*/}
                  <p className="or-divider p-d-flex p-jc-center">OR</p>
                  <div className="sso-login">
                    <Button
                        id="myssologinbutton"
                      label="LOGIN using Broadcom SSO"
                      className="submitBtn"
                      onClick={ssoAuthenticateBcom}
                    />
                  </div>
                </form>
                <p className="register-now-link p-d-flex p-jc-center p-gap-2">
                  Don’t have an account ?{" "}
                  <Link to="/register" className="register-link"><span>Register</span></Link>
                </p>
                <Footer/>
              </div>
            </div>
            {/* <Divider align="center" layout="vertical" className="col-2" style={{margin:"0"}} /> */}
            <div className="login-right-bar p-col-7  right-section-image">
            </div>
          </div>
        </div>}
      </div>
    </div>
  );
}

        export default Login;